import React, { Component } from 'react';

import { Link } from 'gatsby';
import logo from '../../assets/images/image001.jpg';

class NavigationMenu extends Component {
  constructor() {
    super();
    this.state = {
      sticky: false,
    };
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);

    //Mobile Menu
    this.mobileMenu();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 70) {
      this.setState({
        sticky: true,
      });
    } else if (window.scrollY < 70) {
      this.setState({
        sticky: false,
      });
    }
  };

  mobileMenu = () => {
    //Mobile Menu Toggle
    let mainNavToggler = document.querySelector('.menu-toggler');
    let mainNav = document.querySelector('.main-navigation');

    mainNavToggler.addEventListener('click', function () {
      mainNav.style.display =
        mainNav.style.display !== 'block' ? 'block' : 'none';
    });
  };
  render() {
    const { active = 'home' } = this.props;
    return (
      <header className='site-header site-header__header-two '>
        <nav
          className={`navbar navbar-expand-lg navbar-light header-navigation stricky ${
            this.state.sticky ? 'stricked-menu stricky-fixed' : ''
          }`}
        >
          <div className='container clearfix'>
            <div className='logo-box clearfix'>
              <Link to='/' className='navbar-brand'>
                <img
                  src={logo}
                  className='main-logo'
                  width='128'
                  alt='Awesome alter text'
                />
              </Link>
              <button className='menu-toggler' data-target='.main-navigation'>
                <span className='kipso-icon-menu'></span>
              </button>
            </div>
            <div className='main-navigation'>
              <ul className=' navigation-box'>
                <li className={active === 'home' ? 'current' : ''}>
                  <Link to='/'>Home</Link>
                </li>
                <li className={active === 'about' ? 'current' : ''}>
                  <Link to='/about'>About</Link>
                </li>
                <li className={active === 'gallery' ? 'current' : ''}>
                  <Link to='/gallery'>Gallery</Link>
                </li>
                <li className={active === 'contact' ? 'current' : ''}>
                  <Link to='/contact'>Contact</Link>
                </li>
              </ul>
            </div>
            <div className='right-side-box'></div>
          </div>
        </nav>
        <div className='site-header__decor'>
          <div className='site-header__decor-row'>
            <div className='site-header__decor-single'>
              <div className='site-header__decor-inner-1'></div>
            </div>
            <div className='site-header__decor-single'>
              <div className='site-header__decor-inner-2'></div>
            </div>
            <div className='site-header__decor-single'>
              <div className='site-header__decor-inner-3'></div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default NavigationMenu;
