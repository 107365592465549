import { Link } from 'gatsby';
import React from 'react';
import logo from '../../assets/images/image001.jpg';

const Footer = () => {
  function scrollTop() {
    window.scrollTo(0, 0);
  }
  return (
    <div>
      <footer className='site-footer'>
        <div className='site-footer__upper'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-3 col-lg-3 col-sm-3'>
                <div className='footer-widget footer-widget__contact'>
                  <Link to='/' className='navbar-brand'>
                    <img
                      src={logo}
                      className='main-logo'
                      width='128'
                      alt='Awesome alter text'
                    />
                  </Link>
                </div>
              </div>
              <div className='col-xl-3 col-lg-3 col-sm-3'>
                <div className='footer-widget footer-widget__link'>
                  <h2
                    style={{
                      paddingLeft: '80px',
                    }}
                    className='footer-widget__title'
                  >
                    Explore
                  </h2>
                  <div className='footer-widget__link-wrap'>
                    <ul className='list-unstyled footer-widget__link-list'>
                      <li
                        style={{
                          paddingLeft: '90px',
                        }}
                      >
                        <Link to='/'>Home</Link>
                      </li>
                      <li
                        style={{
                          paddingLeft: '90px',
                        }}
                      >
                        <Link to='/about'>About</Link>
                      </li>
                      <li
                        style={{
                          paddingLeft: '88px',
                        }}
                      >
                        <Link to='/gallery'>Gallery</Link>
                      </li>
                      <li
                        style={{
                          paddingLeft: '85px',
                        }}
                      >
                        <Link to='/contact'>Contact</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className='col-xl-6 col-lg-6 col-sm-12'>
                <div className='footer-widget footer-widget__about'>
                  <h2 className='footer-widget__title'>About</h2>
                  <p className='footer-widget__text'>
                    At Avadhani and Associates, our approach to legal practice
                    reflects the persistently developing world around us.
                    Challenges and choices become increasingly complex and
                    regulation more rigid.
                  </p>
                  <div className='footer-widget__btn-block'>
                    <Link to='/contact' className='thm-btn'>
                      Contact
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='site-footer__bottom'>
          <div className='container'>
            <p className='site-footer__copy'>
              &copy; Copyright {new Date().getFullYear()} by Avadhani &
              Associates
            </p>
            <div className='site-footer__social'>
              <div
                onClick={scrollTop}
                onKeyDown={scrollTop}
                role='button'
                tabIndex={0}
                className='scroll-to-target site-footer__scroll-top'
              >
                <i className='kipso-icon-top-arrow'></i>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
export default Footer;
